import React, { useState, useEffect, useContext } from 'react';
import './styles/OrdersPage.css';
import './styles/Admin.css';
import AuthContext from "../context/AuthContext";
import { useWebSocket } from '../context/WebSocketContext';
import { changeOrderStatus, fetchOrders } from "../utils/api";


const OrdersPage = () => {
  const { token } = useContext(AuthContext);
  const ws = useWebSocket();
  const [orders, setOrders] = useState([]);
  const [isPaid, setIsPaid] = useState(false);
  const [inProgressCount, setInProgressCount] = useState(0);
  const [awaitingPaymentCount, setAwaitingPaymentCount] = useState(0);
  const [copyMessage, setCopyMessage] = useState('');

  const handleFetchOrders = async () => {
    const data = await fetchOrders(isPaid, token);
    const filteredData = data.filter(order => order.status !== 'paid' || isPaid);
    setOrders(filteredData);

    const inProgress = filteredData.filter(order => order.status === 'in_progress').length;
    const awaitingPayment = filteredData.filter(order => order.status === 'awaiting_payment').length;

    setInProgressCount(inProgress);
    setAwaitingPaymentCount(awaitingPayment);
  };

  useEffect(() => {
    handleFetchOrders();
  }, [isPaid, token]);

  useEffect(() => {
    if (ws) {
      ws.onmessage = (event) => {
        const { page, orderId, status } = JSON.parse(event.data);
        if (page !== 'order') { return; }

        setOrders(prevOrders => {
          const updatedOrders = prevOrders.map(order =>
            order.id === orderId ? { ...order, status } : order
          );
          return updatedOrders.filter(order => order.status !== 'paid' || isPaid);
        });
      };
    }
  }, [ws, isPaid]);

  const handleToggle = () => {
    setIsPaid(!isPaid);
  };

  const copyToClipboard = (text, elementId) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopyMessage(`Скопійовано: ${text}`);
      const element = document.getElementById(elementId);
      const bElement = element.querySelector('b');
      bElement.classList.add('highlight');
      setTimeout(() => {
        setCopyMessage('');
        bElement.classList.remove('highlight');
      }, 2000); // Сообщение будет показано в течение 2 секунд
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  return (
    <div className="wrap">
      <div className="head">
        <div className="left">
          <h1 id="title" onClick={handleToggle} className="clickable">
            {isPaid ? 'Історія транзакцій' : 'Заявки на оплату'}
          </h1>
          <p
            id="transactionHistory"
            className="clickable"
            onClick={handleToggle}
          >
            {isPaid ? 'Заявки на оплату' : 'Історія транзакцій'}
          </p>
        </div>
        {!isPaid && (
          <div className="right">
            <p>Запитів на перевірці: {inProgressCount}</p>
            <p id="paymentRequests" className="clickable">
              Запитів на оплату: {awaitingPaymentCount}
            </p>
          </div>
        )}
      </div>

      {copyMessage && <div className="copy-message">{copyMessage}</div>}

      <div className="main">
        <div className="body">
          <div className="table">
            <div className="tableHead">
              <div className="tableColumn"><p>ID</p></div>
              <div className="tableColumn"><p>Веб-майстер</p></div>
              <div className="tableColumn"><b>Реквізити</b></div>
              <div className="tableColumn"><b>Сума</b></div>
              <div className="tableColumn"><b>Дата заявки</b></div>
              {isPaid && <div className="tableColumn"><b>Оплачено</b></div>}
              {!isPaid && <div className="tableColumn"><b>Статус</b></div>}
              {!isPaid && <div className="tableColumn"><b>Змінити статус</b></div>}
            </div>
            <div className="tableBody" id="orderTableBody">
              {orders.map(order => (
                <div className="tableRow" key={order.id}>
                  <div className="tableColumn"><b>{order.id}</b></div>
                  <div className="tableColumn">
                    <span>id: {order.user_id}</span>
                    <b>@{order.username}</b>
                  </div>
                  <div
                    id={`requisites-${order.id}`}
                    className="tableColumn"
                    onClick={() => copyToClipboard(order.requisites, `requisites-${order.id}`)}
                  >
                    <span>{order.requisitesType}</span>
                    <b>{order.requisites}</b>
                  </div>
                  <div className="tableColumn"><b>{order.amount}₴</b></div>
                  <div className="tableColumn">
                    <span>{new Date(order.added_at).toLocaleTimeString()}</span>
                    <b>{new Date(order.added_at).toLocaleDateString()}</b>
                  </div>
                  {isPaid ? (
                    <div className="tableColumn"><b>{new Date(order.paid_at).toLocaleDateString()}</b></div>
                  ) : (
                    <>
                      <div className="tableColumn">
                        <b style={{ color: order.status === 'awaiting_payment' ? 'green' : 'orange' }}>
                          {order.status === 'awaiting_payment' ? 'Перевірено' : 'На проверке'}
                        </b>
                      </div>
                      <div className="tableColumn">
                        <select
                          value={order.status}
                          onChange={(e) => changeOrderStatus(order.id, e.target.value, token)}
                        >
                          <option value="in_progress" disabled>На проверке</option>
                          <option value="awaiting_payment">Перевірено</option>
                          <option value="paid">Оплачено</option>
                        </select>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersPage;

import React, { useState, useEffect, useContext, useCallback } from 'react';
import './styles/TrafficCheckPage.css';
import './styles/Admin.css';
import './styles/Modal.css';
import AuthContext from '../context/AuthContext';
import { useWebSocket } from '../context/WebSocketContext';
import './styles/TrafficCheckReviewPage.css';
import { fetchCheckOffers, updateRequestStatus } from "../utils/api";

const TrafficCheckReviewPage = () => {
  const { token } = useContext(AuthContext);
  const ws = useWebSocket();
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [modalData, setModalData] = useState(null);
  const [confirmationData, setConfirmationData] = useState(null);

  const handleFetchCheckOffers = useCallback(async () => {
    setLoading(true);
    const data = await fetchCheckOffers(page, token);
    setLoading(false);
    if (data) {
      setOffers(data.offers);
      setTotalPages(data.pages);
    }
  }, [page, token]);

  useEffect(() => {
    handleFetchCheckOffers();
  }, [handleFetchCheckOffers]);

  useEffect(() => {
    if (ws) {
      ws.onmessage = (event) => {
        const { page: wsPage, link_name, count, date } = JSON.parse(event.data);
        if (wsPage !== 'TrafficReview') {
          return;
        }

        setOffers((prevOffers) => {
          return prevOffers.map((offer) => {
            const updatedUsers = offer.users.map((user) => {
              const updatedLinks = user.links.map((link) => {
                if (link.title === link_name) {
                  const updatedLinkCountInfo = link.linkCountInfo.map((info) => {
                    if (info.date === date) {
                      return {
                        ...info,
                        approvedCount: info.undefinedCount - count,
                        unapprovedCount: count,
                        undefinedCount: 0,
                      };
                    }
                    return info;
                  });
                  return { ...link, linkCountInfo: updatedLinkCountInfo };
                }
                return link;
              });
              return { ...user, links: updatedLinks };
            });
            return { ...offer, users: updatedUsers };
          });
        });
      };
    }
  }, [ws]);

  const openModal = (date, countInfo, link) => {
    if (countInfo?.undefinedCount === 0) { return; }
    setModalData({ date, audience: countInfo?.undefinedCount || 0, link });
    document.getElementById('offers').classList.add('blur');
  };

  const closeModal = () => {
    setModalData(null);
    setConfirmationData(null);
    document.getElementById('offers').classList.remove('blur');
  };

  const handleSave = (data) => {
    setConfirmationData(data);
    setModalData(null);
  };

  const confirmSave = async () => {
    const action = confirmationData.action === 'write-off' ? "decline" : "approve";
    const requestPayload = {
      action,
      dateRange: { startDate: confirmationData.date, endDate: confirmationData.date },
      scope: "partial",
      count: confirmationData.action === 'write-off' ? parseInt(confirmationData.quantity) : 0,
      comment: confirmationData.comment || null,
      linkId: confirmationData.link.split('/')[1],
    };

    try {
      const response = await updateRequestStatus(token, requestPayload);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error('Network response was not ok');
      }

      closeModal();
      handleFetchCheckOffers();
    } catch (error) {
      console.error('Error handling request:', error);
    }
  };

  const filterOffers = (offers) => {
    return offers.map(offer => {
      const filteredUsers = offer.users.map(user => {
        const filteredLinks = user.links.filter(link => {
          const allZeros = link.linkCountInfo.every(info => info.approvedCount === 0 && info.unapprovedCount === 0 && info.undefinedCount === 0);
          return !allZeros;
        });
        return { ...user, links: filteredLinks };
      }).filter(user => user.links.length > 0);

      return { ...offer, users: filteredUsers };
    }).filter(offer => offer.users.length > 0);
  };

  return (
    <div className="wrap">
      <div className="head">
        <h1>Не перевірений трафік</h1>
      </div>
      <div id="offers" className="offers">
        {loading && <p>Завантаження...</p>}
        {offers.length > 0 ? (
          filterOffers(offers).map((offer, index) => (
            <RenderOffer key={index} offer={offer} openModal={openModal} />
          ))
        ) : (
          <p>Не знайдено оферів</p>
        )}
      </div>
      <Pagination page={page} totalPages={totalPages} setPage={setPage} loading={loading} />
      {modalData && <Modal data={modalData} onSave={handleSave} onClose={closeModal} />}
      {confirmationData && <ConfirmationModal data={confirmationData} onConfirm={confirmSave} onClose={closeModal} />}
    </div>
  );
};

const RenderOffer = ({ offer, openModal }) => {
  const dates = [];
  offer.users.forEach(user => {
    user.links.forEach(link => {
      link.linkCountInfo.forEach(info => {
        if (!dates.includes(info.date)) {
          dates.push(info.date);
        }
      });
    });
  });

  dates.sort();

  return (
    <div className="offer">
      <table>
        <thead>
          <tr>
            <th className="sticky-col offer-title" colSpan="2">{offer.title}-{offer.id}</th>
            {dates.map((date, index) => (
              <th key={index} className="date-header">{date}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {offer.users.map((user, userIndex) => (
            <UserRow key={userIndex} user={user} dates={dates} openModal={openModal} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const UserRow = ({ user, dates, openModal }) => {
  const filteredLinks = user.links.filter(link => {
    const hasUndefined = link.linkCountInfo.some(info => info.undefinedCount !== 0);
    return hasUndefined;
  });

  return filteredLinks.map((link, linkIndex) => {
    const allZeros = dates.every(date => {
      const countInfo = link.linkCountInfo.find(info => info.date === date);
      const approvedCount = parseInt(countInfo?.approvedCount, 10) || 0;
      const unapprovedCount = parseInt(countInfo?.unapprovedCount, 10) || 0;
      const undefinedCount = parseInt(countInfo?.undefinedCount, 10) || 0;

      return approvedCount === 0 && unapprovedCount === 0 && undefinedCount === 0;
    });

    if (allZeros) return null;

    return (
      <tr key={linkIndex}>
        {linkIndex === 0 && (
          <td className="sticky-col" rowSpan={filteredLinks.length}>
            {user.username ? `@${user.username}` : 'N/A'}
            <div className="small-gray-link">{user.userId}, <span>{user.fullname}</span></div>
          </td>
        )}
        <td className="second-sticky-col">
          <div className="top-right-text">{link.title || 'N/A'}</div>
          <div className="small-gray-link top-right-link">t.me/+{link.link_hash}</div>
        </td>
        {dates.map((date, dateIndex) => {
          const countInfo = link.linkCountInfo.find(info => info.date === date);
          const approvedCount = parseInt(countInfo?.approvedCount, 10) || 0;
          const unapprovedCount = parseInt(countInfo?.unapprovedCount, 10) || 0;
          const undefinedCount = parseInt(countInfo?.undefinedCount, 10) || 0;

          if (approvedCount === 0 && unapprovedCount === 0 && undefinedCount === 0) {
            return <td key={dateIndex}></td>;
          }

          return (
            <td key={dateIndex}>
              <div
                className="number-container"
                onClick={() => {
                  if (undefinedCount === 0) {
                    return;
                  }
                  openModal(date, countInfo, `t.me/+${link.link_hash}`);
                }}
              >
                {approvedCount !== 0 && <span className="up">{approvedCount}</span>}
                {unapprovedCount !== 0 && <span className="down">{unapprovedCount}</span>}
                {undefinedCount !== 0 && <span className="undefined">{undefinedCount}</span>}
              </div>
            </td>
          );
        })}
      </tr>
    );
  });
};

const Modal = ({ data, onSave, onClose }) => {
  const [action, setAction] = useState('approve');
  const [quantity, setQuantity] = useState('');
  const [comment, setComment] = useState('');

  const handleSaveClick = () => {
    onSave({ ...data, action, quantity, comment });
  };

  return (
    <div className="modal">
      <div className="container" id="initial-window">
        <h2>Апрув</h2>
        <div className="form-group">
          <div className="inline">
            <label>Оберіть дату</label>
            <input type="date" value={data.date} readOnly />
          </div>
          <p>Посилання: <a href={`https://${data.link}`}>{data.link}</a></p>
          <p>Аудиторія: {data.audience}</p>
          <div className="inline">
            <input type="radio" name="action" value="approve" checked={action === 'approve'} onChange={() => setAction('approve')} /> Схвалити
            <input type="radio" name="action" value="write-off" checked={action === 'write-off'} onChange={() => setAction('write-off')} /> Списати
          </div>
          {action === 'write-off' && (
            <div id="write-off-fields">
              <div className="inline">
                <label>Кількість заявок</label>
                <input type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
              </div>
              <div className="inline">
                <label>Коментар</label>
                <input type="text" value={comment} onChange={(e) => setComment(e.target.value)} />
              </div>
            </div>
          )}
        </div>
        <div className="actions">
          <button onClick={handleSaveClick}>Зберігти</button>
          <button className="cancel" onClick={onClose}>Відмінити</button>
        </div>
      </div>
    </div>
  );
};

const ConfirmationModal = ({ data, onConfirm, onClose }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleConfirm = async () => {
    setIsProcessing(true);
    await onConfirm();
    setIsProcessing(false);
  };

  return (
    <div className="modal">
      <div className="container" id="confirmation">
        <h2>Підтвердження дії</h2>
        <p>Посилання: <a href={`https://${data.link}`}>{data.link}</a></p>
        <p>Дата: {data.date}</p>
        <p>Аудиторія: {data.audience}</p>
        {data.action === 'write-off' ? (
          <p>Кількість списання: {data.quantity}<br />Коментар: {data.comment}</p>
        ) : (
          <p>Дія: Схвалити</p>
        )}
        <div className="actions">
          <button
            onClick={handleConfirm}
            disabled={isProcessing}
            className={isProcessing ? 'disabled' : ''}
          >
            Підтвердити
          </button>
          <button
            className="cancel"
            onClick={onClose}
            disabled={isProcessing}
            style={{ opacity: isProcessing ? 0.5 : 1 }}
          >
            Відмінити
          </button>
        </div>
      </div>
    </div>
  );
};

const Pagination = ({ page, totalPages, setPage, loading }) => {
  return (
    <div className="pagination">
      <button
        onClick={() => !loading && setPage((prev) => Math.max(prev - 1, 1))}
        disabled={loading || page === 1}
        className={loading ? 'disabled' : ''}
      >
        Назад
      </button>
      <span>{page}/{totalPages}</span>
      <button
        onClick={() => !loading && setPage((prev) => Math.min(prev + 1, totalPages))}
        disabled={loading || page === totalPages}
        className={loading ? 'disabled' : ''}
      >
        Вперед
      </button>
    </div>
  );
};

export default TrafficCheckReviewPage;

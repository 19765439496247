import React, { useState } from 'react';
import Cookies from 'js-cookie';
import './styles/SignIn.css';
import apiUrl from '../config';

const SignIn = () => {
  const [error, setError] = useState(null);

  const handleSignin = async () => {
    try {
      const response = await fetch(`${apiUrl}/telegram_registration`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch redirect URL');
      }

      const data = await response.json();
      Cookies.set('session_id', data.session_id);
      window.location.href = data.url;
    } catch (error) {
      setError('Failed to sign in. Please try again.');
      console.error(error);
    }
  };

  return (
    <div className="container">
      <button className="signin-button" onClick={handleSignin}>Sign in</button>
      <div className="content">
        <div className="checkmark-circle">
          <div className="checkmark"></div>
        </div>
        <h1>Unauthorized</h1>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default SignIn;

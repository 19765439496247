import apiUrl from "../config";

export const fetchOffers = async (requestData, token) => {
  try {
    const response = await fetch(`${apiUrl}/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching offers:', error);
    return null;
  }
};

export const fetchCheckOffers = async (page, token) => {
  try {
    const response = await fetch(`${apiUrl}/search-check`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ page }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching offers:', error);
    return null;
  }
};

export const updateRequestStatus = async (token, requestPayload) => {
  const response = await fetch(`${apiUrl}/requests`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(requestPayload),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response;
};

export const fetchOrders = async (isPaid, token) => {
  const response = await fetch(`${apiUrl}/order`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify({ is_paid: isPaid, start: 0, end: 1000 }),
  });
  const data = await response.json();
  return data.orders;
};

export const changeOrderStatus = async (orderId, status, token) => {
  const response = await fetch(`${apiUrl}/change-order-status`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify({ orderId, status }),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

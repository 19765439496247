import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import NavigationPage from './components/NavigationPage';
import OrdersPage from './components/OrdersPage';
import TrafficCheckPage from './components/TrafficCheckPage';
import SignIn from './components/SignIn';
import TelegramLogin from './components/TelegramLogin';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import TrafficCheckReviewPage from "./components/TrafficCheckReviewPage";
import {WebSocketProvider} from "./context/WebSocketContext";

const App = () => {
  return (
    <AuthProvider>
      <WebSocketProvider>
        <Router>
          <Routes>
            <Route path="/terra-bot/approve/" element={<ProtectedRoute><NavigationPage /></ProtectedRoute>} />
            <Route path="/terra-bot/approve/orders" element={<ProtectedRoute><OrdersPage /></ProtectedRoute>} />
            <Route path="/terra-bot/approve/traffic-check" element={<ProtectedRoute><TrafficCheckPage /></ProtectedRoute>} />
            <Route path="/terra-bot/approve/telegram_login" element={<TelegramLogin />} />
            <Route path="/terra-bot/approve/traffic-check-review" element={<ProtectedRoute><TrafficCheckReviewPage /></ProtectedRoute>} /> {/* Новый маршрут */}
            <Route path="/terra-bot/approve/sign-in" element={<SignIn />} /> {/* Добавить маршрут для SignIn */}
          </Routes>
        </Router>
      </WebSocketProvider>
    </AuthProvider>
  );
};

export default App;

import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import apiUrl from '../config';
import SignIn from './SignIn';

const ProtectedRoute = ({ children }) => {
  const { token } = useContext(AuthContext);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      if (!token) {
        setIsAuthenticated(false);
        return;
      }

      try {
        const response = await fetch(`${apiUrl}/check_auth`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (data.success) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Error checking auth:', error);
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, [token]);

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate('/terra-bot/approve/sign-in'); // Перенаправить на страницу входа
    }
  }, [isAuthenticated, navigate]);

  if (isAuthenticated === null) {
    return <div>Завантаження...</div>; // Пока идет проверка, можно показать загрузочный индикатор
  }

  return isAuthenticated ? children : <SignIn />;
};

export default ProtectedRoute;

import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import AuthContext from '../context/AuthContext';
import apiUrl from '../config';

const TelegramLogin = () => {
  const { login } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const tg_code = query.get('tg_code');
    const session_id = Cookies.get('session_id');

    console.log('tg_code:', tg_code); // Отладка
    console.log('session_id:', session_id); // Отладка

    if (tg_code && session_id) {
      const fetchToken = async () => {
        try {
          const response = await fetch(`${apiUrl}/telegram_login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              tg_code,
              session_id,
            }),
          });

          if (!response.ok) {
            throw new Error('Failed to fetch token');
          }

          const data = await response.json();

          if (data.access_token) {
            login(data.access_token);
            window.location.href = '/terra-bot/approve/'; // Redirect to the main page after login
          } else {
            console.error('Error:', data.error || 'Unknown error');
            // Обработайте ошибку здесь, например, покажите сообщение пользователю
          }
        } catch (error) {
          console.error('Error logging in:', error);
        }
      };

      fetchToken();
    }
  }, [location, login]);

  return <div>Вхiд...</div>;
};

export default TelegramLogin;

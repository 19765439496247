import React from 'react';
import { Link } from 'react-router-dom';
import './styles/NavLink.css';

const NavLink = ({ url, label }) => {
  return (
    <Link className="nav-link" to={url}>
      {label}
    </Link>
  );
};

export default NavLink;

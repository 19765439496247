import React, { useState, useEffect, useContext } from 'react';
import './styles/TrafficCheckPage.css';
import './styles/Admin.css';
import './styles/Modal.css';
import AuthContext from '../context/AuthContext';
import { useWebSocket } from '../context/WebSocketContext';
import {fetchOffers, updateRequestStatus} from "../utils/api";



const TrafficCheckPage = () => {
  const { token } = useContext(AuthContext);
  const ws = useWebSocket();
  const [linkOrName, setLinkOrName] = useState('');
  const [offerId, setOfferId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [modalData, setModalData] = useState(null);
  const [confirmationData, setConfirmationData] = useState(null);

  const handleFetchOffers = async () => {
    setLoading(true);
    const requestData = {
      linkOrName: linkOrName || null,
      offerId: offerId ? parseInt(offerId) : null,
      dateRange: startDate && endDate ? { startDate, endDate } : null,
      start: 0,
      end: 1000,
    };

    const data = await fetchOffers(requestData, token);
    setLoading(false);
    if (data) {
      setOffers(data);
    }
  };

  useEffect(() => {
    handleFetchOffers();
  }, [token]);

  useEffect(() => {
    if (ws) {
      ws.onmessage = (event) => {
        const { page, link_name, count, date } = JSON.parse(event.data);
        if (page !== 'TrafficReview') {
          return;
        }

        setOffers((prevOffers) => {
          return prevOffers.map((offer) => {
            const updatedUsers = offer.users.map((user) => {
              const updatedLinks = user.links.map((link) => {
                if (link.title === link_name) {
                  const updatedLinkCountInfo = link.linkCountInfo.map((info) => {
                    if (info.date === date) {
                      return {
                        ...info,
                        approvedCount: info.undefinedCount - count ,
                        unapprovedCount: count,
                        undefinedCount: 0,
                      };
                    }
                    return info;
                  });
                  return { ...link, linkCountInfo: updatedLinkCountInfo };
                }
                return link;
              });
              return { ...user, links: updatedLinks };
            });
            return { ...offer, users: updatedUsers };
          });
        });
      };
    }
  }, [ws]);

  const openModal = (date, countInfo, link) => {
    if (countInfo?.undefinedCount === 0) {return;}
    setModalData({ date, audience: countInfo?.undefinedCount || 0, link });
    document.getElementById('offers').classList.add('blur');
  };

  const closeModal = () => {
    setModalData(null);
    setConfirmationData(null);
    document.getElementById('offers').classList.remove('blur');
  };

  const handleSave = (data) => {
    setConfirmationData(data);
    setModalData(null);
  };

  const confirmSave = async () => {
  const action = confirmationData.action === 'write-off' ? "decline" : "approve";
  const requestPayload = {
    action,
    dateRange: { startDate: confirmationData.date, endDate: confirmationData.date },
    scope: "partial",
    count: confirmationData.action === 'write-off' ? parseInt(confirmationData.quantity) : 0,
    comment: confirmationData.comment || null,
    linkId: confirmationData.link.split('/')[1],
  };

  try {
    const response = await updateRequestStatus(token, requestPayload);

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response:', errorText);
      throw new Error('Network response was not ok');
    }

    closeModal();
    handleFetchOffers();
  } catch (error) {
    console.error('Error handling request:', error);
  }
};


  return (
    <div className="wrap">
      <div className="head">
        <h1>Перевірка трафіку</h1>
      </div>
      <div className="filters">
        <input
          type="text"
          id="linkOrName"
          className="_big"
          placeholder="Пошук за посиланням"
          value={linkOrName}
          onChange={(e) => setLinkOrName(e.target.value)}
        />
        <input
          type="text"
          id="offerId"
          className="_small"
          placeholder="Пошук за ID оферу"
          value={offerId}
          onChange={(e) => setOfferId(e.target.value)}
        />
        <input
          type="date"
          id="startDate"
          className="_small"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          type="date"
          id="endDate"
          className="_small"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <button id="fetch-button" onClick={handleFetchOffers} disabled={loading} className={loading ? 'disabled' : ''}>
          Пошук
        </button>
      </div>
      <div id="offers" className="offers">
        {loading && <p>Завантаження...</p>}
        {offers.length > 0 ? (
          offers.map((offer, index) => (
            <RenderOffer key={index} offer={offer} openModal={openModal} />
          ))
        ) : (
          <p>Не знайдено оферів</p>
        )}
      </div>
      {modalData && <Modal data={modalData} onSave={handleSave} onClose={closeModal} />}
      {confirmationData && <ConfirmationModal data={confirmationData} onConfirm={confirmSave} onClose={closeModal} />}
    </div>
  );
};

const RenderOffer = ({ offer, openModal }) => {
  const dates = [];
  offer.users.forEach(user => {
    user.links.forEach(link => {
      link.linkCountInfo.forEach(info => {
        if (!dates.includes(info.date)) {
          dates.push(info.date);
        }
      });
    });
  });

  dates.sort();

  return (
    <div className="offer">
      <table>
        <thead>
          <tr>
            <th className="sticky-col offer-title" colSpan="2">{offer.title}-{offer.id}</th>
            {dates.map((date, index) => (
              <th key={index} className="date-header">{date}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {offer.users.map((user, userIndex) => (
            <UserRow key={userIndex} user={user} dates={dates} openModal={openModal} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const UserRow = ({ user, dates, openModal }) => {
  return user.links.map((link, linkIndex) => (
    <tr key={linkIndex}>
      {linkIndex === 0 && (
        <td className="sticky-col" rowSpan={user.links.length}>
          {user.username ? `@${user.username}` : 'N/A'}
          <div className="small-gray-link">{user.userId}, <span>{user.fullname}</span></div>
        </td>
      )}
      <td className="second-sticky-col">
        <div className="top-right-text">{link.title || 'N/A'}</div>
        <div className="small-gray-link top-right-link">t.me/+{link.link_hash}</div>
      </td>
      {dates.map((date, dateIndex) => {
        const countInfo = link.linkCountInfo.find(info => info.date === date);
        const approvedCount = parseInt(countInfo?.approvedCount, 10) || 0;
        const unapprovedCount = parseInt(countInfo?.unapprovedCount, 10) || 0;
        const undefinedCount = parseInt(countInfo?.undefinedCount, 10) || 0;

        if (approvedCount === 0 && unapprovedCount === 0 && undefinedCount === 0) {
          return <td key={dateIndex}></td>; // Пустая ячейка
        }

        return (
          <td key={dateIndex}>
            <div
              className="number-container"
              onClick={() => {
                if (undefinedCount === 0) {
                  return;
                }
                openModal(date, countInfo, `t.me/+${link.link_hash}`);
              }}
            >
              {approvedCount !== 0 && <span className="up">{approvedCount}</span>}
              {unapprovedCount !== 0 && <span className="down">{unapprovedCount}</span>}
              {undefinedCount !== 0 && <span className="undefined">{undefinedCount}</span>}
            </div>
          </td>
        );
      })}
    </tr>
  ));
};



const Modal = ({ data, onSave, onClose }) => {
  const [action, setAction] = useState('approve');
  const [quantity, setQuantity] = useState('');
  const [comment, setComment] = useState('');

  const handleSaveClick = () => {
    onSave({ ...data, action, quantity, comment });
  };

  return (
    <div className="modal">
      <div className="container" id="initial-window">
        <h2>Апрув</h2>
        <div className="form-group">
          <div className="inline">
            <label>Оберіть дату</label>
            <input type="date" value={data.date} readOnly />
          </div>
          <p>Посилання: <a href={`https://${data.link}`}>{data.link}</a></p>
          <p>Аудиторія: {data.audience}</p>
          <div className="inline">
            <input type="radio" name="action" value="approve" checked={action === 'approve'} onChange={() => setAction('approve')} /> Схвалити
            <input type="radio" name="action" value="write-off" checked={action === 'write-off'} onChange={() => setAction('write-off')} /> Списати
          </div>
          {action === 'write-off' && (
            <div id="write-off-fields">
              <div className="inline">
                <label>Кількість заявок</label>
                <input type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
              </div>
              <div className="inline">
                <label>Коментар</label>
                <input type="text" value={comment} onChange={(e) => setComment(e.target.value)} />
              </div>
            </div>
          )}
        </div>
        <div className="actions">
          <button onClick={handleSaveClick}>Зберігти</button>
          <button className="cancel" onClick={onClose}>Відмінити</button>
        </div>
      </div>
    </div>
  );
};

const ConfirmationModal = ({ data, onConfirm, onClose }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleConfirm = async () => {
    setIsProcessing(true);
    await onConfirm();
    setIsProcessing(false);
  };

  return (
    <div className="modal">
      <div className="container" id="confirmation">
        <h2>Підтвердження дії</h2>
        <p>Посилання: <a href={`https://${data.link}`}>{data.link}</a></p>
        <p>Дата: {data.date}</p>
        <p>Аудиторія: {data.audience}</p>
        {data.action === 'write-off' ? (
          <p>Кількість списання: {data.quantity}<br />Коментар: {data.comment}</p>
        ) : (
          <p>Дія: Схвалити</p>
        )}
        <div className="actions">
          <button
            onClick={handleConfirm}
            disabled={isProcessing}
            className={isProcessing ? 'disabled' : ''}
          >
            Підтвердити
          </button>
          <button
            className="cancel"
            onClick={onClose}
            disabled={isProcessing}
            style={{ opacity: isProcessing ? 0.5 : 1 }}
          >
            Відмінити
          </button>
        </div>
      </div>
    </div>
  );
};

export default TrafficCheckPage;

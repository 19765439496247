import React from 'react';
import NavLink from './NavLink';
import './styles/NavigationPage.css';

const NavigationPage = () => {
  return (
    <div className="container">
      <h1 className="title">Навігаційна сторінка</h1>
      <NavLink url="/terra-bot/approve/orders" label="Виплати" />
      <NavLink url="/terra-bot/approve/traffic-check" label="Перевірка трафіку" />
      <NavLink url="/terra-bot/approve/traffic-check-review" label="Не перевірений трафік"/>
    </div>
  );
};

export default NavigationPage;

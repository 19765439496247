import React, { createContext, useContext, useEffect, useState, useCallback, useRef } from 'react';
import apiUrl from "../config";

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [isRetrying, setIsRetrying] = useState(false);
  const socketRef = useRef(null);

  const connectWebSocket = useCallback(() => {
    if (isRetrying) {
      return;
    }

    const retryConnection = () => {
      if (retryCount < 5) { // Retry up to 5 times
        setIsRetrying(true);
        setTimeout(() => {
          setRetryCount(prev => prev + 1);
          setIsRetrying(false);
          connectWebSocket();
        }, 2000); // Wait 2 seconds before retrying
      } else {
        console.error('Max retry attempts reached');
      }
    };

    const ws = new WebSocket(`wss://${apiUrl.split('://')[1]}/ws`);

    ws.onopen = () => {
      console.log('Connected to WebSocket');
      setIsConnected(true);
      setRetryCount(0); // Reset retry count on successful connection
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket');
      setIsConnected(false);
      retryConnection();
    };

    ws.onerror = (error) => {
      console.error('WebSocket Error', error);
      setIsConnected(false);
      retryConnection();
    };

    ws.onmessage = (message) => {
      console.log('WebSocket message received:', message);
    };

    socketRef.current = ws;
  }, [retryCount, isRetrying]);

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [connectWebSocket]);

  if (!isConnected) {
    return <div>Встановлення з'єднання...</div>;
  }

  return (
    <WebSocketContext.Provider value={socketRef.current}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);
